.categoria {
  border-radius: 8px;
  background-color: white;
  padding: 1rem 1.2rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .categoria_cabecera {
    display: flex;
    justify-content: space-between;

    h1 {
      flex: 1;
      cursor: pointer;

      span {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .fecha {
      text-align: right;
    }
  }

  .categoria_cuerpo {
    padding-top: 0.7rem;
    transition: max-height 3000ms;
    font-size: 1.1rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      li {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .cuerpo_show {
    max-height: auto;
    display: block;
  }

  .cuerpo_hide {
    max-height: 0;
    display: none;
  }
}

.button {
  height: auto;
  width: auto;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.7rem 0;

  border: 1px solid #02394a;
  border-radius: 8px;
  color: #fff;
  background-color: green;
  font-weight: 600;

  cursor: pointer;

  &:disabled {
    background-color: #02394a;
  }
}
