.container {
    height: 100vh;

    display: flex;

    align-items: center;
    justify-content: center;

    form {
        display: flex;
        flex-direction: column;
        width: 18rem;

        .field {
            display: flex;
            flex-direction: column;

            margin-top: 1.2rem;

            label {
                margin-left: 0.1rem;
                margin-bottom: .3rem;
                font-weight: 500;
            }

            input {
                padding: .5rem .75rem;
                font-size: 1rem;
                font-family: 'Nunito';
            }
        }

        .loginButton {
            margin-top: 2rem;
            font-family: 'Nunito';
            font-size: 1.2rem;

            background-color: #02394a;
            color: #CCD7DA;
        }
    }
}
