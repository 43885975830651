.chipsContainer {
  display: flex;
  flex-wrap: wrap;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 31;

  background-color: #02394a;

  padding: 1rem 1.5rem;
  gap: 0.5rem;
}
