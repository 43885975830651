.container {
  position: fixed;
  height: 100%;
  width: 100%;

  transition: left 400ms;

  z-index: 1000;
}

.cuerpo {
  height: 100%;
  width: 24rem;
  background-color: #ccd7da;
  border-right: #02394a solid 1px;

  overflow: auto;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 425px) {
    width: 100%;

    border: none;
  }

  .cabecera {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.7rem 0.6rem 0.7rem 1.2rem;

    .closeBttn {
      border: none;
      background-color: transparent;
      width: auto;
      padding: 0;

      span {
        font-size: 2.8rem;
      }
    }
  }
}

.pie {
  padding: 1rem;
}

.show {
  left: 0;
}

.hide {
  left: -100%;
}
