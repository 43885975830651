//    ------  Generales   ------    //

.contenedor {
  height: 100vh;
  width: 24rem;

  position: fixed;

  background-color: #ccd7da;
  border-left: #02394a solid 1px;

  transition: right 400ms;

  overflow: auto;

  display: flex;
  flex-direction: column;

  z-index: 1000;

  .cabecera {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.7rem 0.6rem 0.7rem 1.2rem;

    .closeBttn {
      border: none;
      background-color: transparent;
      width: auto;
      padding: 0;

      span {
        font-size: 2.8rem;
      }
    }
  }

  .body {
    padding: 0rem 1.2rem 2rem;
    h1 {
      font-size: 1.3rem;
      font-weight: 400;
    }

    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  @media screen and (max-width: 425px) {
    width: 100%;

    border: none;
  }
}

.show {
  right: 0;
}

.hide {
  right: -100%;
}

.tarjetaDatos {
  padding: 1rem 1.2rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  border-radius: 8px;
  background-color: white;
}

.okButton {
  background-color: green;
  color: white;
}

.passiveButton {
  background-color: yellow;
}

.badButton {
  background-color: red;
  color: white;
}

button {
  width: 100%;
  padding: 1rem;

  border: 1px solid #02394a;

  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;

  text-decoration: none;

  cursor: pointer;

  color: #02394a;
}
//    ------  Generales   ------    //

//    ------  Sin asignar   ------    //

.cuerpoAsigna {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: stretch;

  gap: 1rem;

  select {
    padding: 1rem 0.5rem;
    text-align: center;
    font-size: 1.2rem;

    border-radius: 8px;
  }

  button {
    padding: 1rem;

    border: 1px solid #02394a;
    background-color: #f8b6fc;

    font-size: 1rem;
    border-radius: 8px;

    text-decoration: none;

    color: #02394a;
  }
}

//    ------  Generales   ------    //

//    ------  Sin contactar    ------    //

.cotizacion_form {
  border-radius: 8px;
  background-color: white;
  padding: 1rem 1.2rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .cotizacion_cabecera {
    display: flex;
    justify-content: space-between;
  }

  .cotizacion_cuerpo {
    padding-top: 0.7rem;

    .cotizacion_field {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      gap: 8px;

      textarea {
        font-size: 1.2rem;
        padding: 0.5rem 0.7rem;
        font-family: "Nunito", sans-serif;
      }

      select {
        font-size: 1.2rem;
        padding: 0.5rem 0.7rem;
        font-family: "Nunito", sans-serif;
      }

      .cotizacion_input {
        display: flex;
        flex-direction: column;
        input {
          font-size: 1.2rem;
          padding: 0.5rem 0.7rem;
        }
      }

      .cotizacion_input_inicio {
        display: inline-block;
        position: relative;
        overflow: hidden;

        input {
          font-size: 1.2rem;
          padding: 0.5rem 0.7rem 0.5rem 1.3rem;
          width: 87%;
        }
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 7px;
          font-size: 1.2rem;
        }
      }

      .cotizacion_input_final {
        display: inline-block;
        position: relative;
        overflow: hidden;

        input {
          font-size: 1.2rem;
          padding: 0.5rem 0.7rem;
          width: 91%;
        }
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.comentario_form {
  border-radius: 8px;
  background-color: white;
  padding: 1rem 1.2rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .comentario_cabecera {
    display: flex;
    justify-content: space-between;
  }

  .cotizacion_cuerpo {
    padding-top: 0.7rem;

    display: flex;
    flex-direction: column;

    gap: 1.2rem;

    textarea{
      resize: vertical;
    }
  }
}

.stepButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  gap: 1.4rem;
  padding-bottom: 1.4rem;

  .okButton {
    background-color: green;
    color: white;
  }

  .passiveButton {
    background-color: yellow;
  }

  .badButton {
    background-color: red;
    color: white;
  }
}
