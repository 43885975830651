.contenedor {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  gap: 0rem 1rem;
}

.cabecera {
  margin: 0;
  padding: 0.3rem 0rem 0rem 0rem;
  cursor: pointer;

  span {
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.saludo {
  grid-area: saludo;
  padding: 1.2rem 1.5rem;
  display: flex;

  justify-content: space-between;
}

.meta {
  grid-area: meta;

  display: flex;
  flex-direction: column;

  align-items: flex-end;
  padding: 1rem 1.5rem;
}

.busqueda {
  grid-area: busqueda;

  display: flex;
  justify-content: center;

  input {
    font-size: 1.3rem;
  }
}

.cuerpo {
  grid-area: cuerpo;

  min-height: 18rem;
}

.clientesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  gap: 1.5rem;

  .clienteCard {
    width: 18rem;
    height: auto;

    padding: 0.5rem 0.8rem;

    border: 1px solid black;

    border-radius: 8px;

    h1 {
      font-size: 1rem;
    }

    .clienteCardCabecera {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.7rem;
    }
  }
}

.adminButton {
  position: fixed;
  bottom: 0;
  right: 0;

  padding: 1rem;
  margin: 1rem 1.2rem;
}

// .extraButtons {
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   display: flex;
//   flex-direction: column;
//   gap: 0.7rem;
//   padding: 1rem;

//   a {
//     width: 35px;
//     height: 35px;

//     padding: 1rem;
//     line-height: 35px;
//     border-radius: 50%;
//     font-size: 38px;

//     cursor: pointer;
//     font-family: "Roboto", sans-serif;

//     color: #02394a;
//     background: #f8b6fc;
//     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

//     text-decoration: none;
//     text-align: center;

//     span {
//       font-size: 2.3rem;
//     }
//   }
// }

.extraButtons {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem;

  z-index: 31;

  button {
    width: 45px;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem;
    border-radius: 50%;
    font-size: 38px;

    color: #02394a;
    background: #f8b6fc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    text-decoration: none;

    span {
    }
  }
}
