.clienteCard {
  width: 18rem;
  height: auto;

  display: flex;
  flex-direction: column;

  padding: 0.5rem 0.8rem;

  border: 2px solid #02394a;

  border-radius: 8px;

  margin: .5rem 0 ;
  margin-top: 0;

  h1 {
    font-size: 1rem;
  }

  background-color: #e5ebec;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .clienteCardCabecera {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.7rem;
    h1 {
      flex: 1;
      text-align: end;
    }
  }
}

// El color cambia con el tiempo

.uno {
  border-color: green;
}
.dos {
  border-color: yellow;
}
.mas {
  border-color: red;
}

.pagoDetails {
  display: flex;
  justify-content: space-between;
}

.seleccionado {
  background-color: #02394a;
  color: #e5ebec;
}
