$large: 425px;
$tablet: 768px;
$laptop: 1024px;

.contenedor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);

  gap: 0rem 1rem;

  grid-template-areas:
    "saludo saludo"
    "cuerpo cuerpo";
}

.busquedaContainer {
  display: flex;
  align-items: center;

  span {
    font-size: 2.5rem;
    color: red;
    margin-right: 0.5rem;

    cursor: pointer;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  form {
    width: 120px;
  }

  @media screen and (min-width: $large) {
    form {
      width: 170px;
    }
  }
  @media screen and (min-width: $tablet) {
    form {
      width: 500px;
    }
  }
}

.saludo {
  grid-area: saludo;
  padding: 1rem 1.5rem;
  display: flex;

  justify-content: space-between;
}

.meta {
  grid-area: meta;

  display: flex;
  flex-direction: column;

  align-items: flex-end;
  padding: 1rem 1.5rem;
}

.cuerpo {
  grid-area: cuerpo;

  min-height: 18rem;
}

.clientesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  gap: 1.5rem;

  .clienteCard {
    width: 18rem;
    height: auto;

    padding: 0.5rem 0.8rem;

    border: 1px solid black;

    border-radius: 8px;

    h1 {
      font-size: 1rem;
    }

    .clienteCardCabecera {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.7rem;
    }
  }
}

.extraButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem;
  z-index: 31;

  button {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 3.2rem;
    height: 3.2rem;

    padding: 1rem;

    border-radius: 50%;

    border: none;
    background: #f8b6fc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    span {
      font-size: 2rem;
    }
  }

  .adminButton {
    width: 22px;
    height: 22px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;

    border-radius: 50%;

    cursor: pointer;
    font-family: "Roboto", sans-serif;

    color: #02394a;
    background: #f8b6fc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    text-decoration: none;
    text-align: center;

    span {
      font-size: 2rem;
    }
  }
}
