body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Outfit", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ccd7da;
}

p {
  margin: 0;
  font-family: "Outfit", sans-serif;
  cursor: default;
}

input {
  margin: 0;
  font-family: "Outfit", sans-serif;
}

h1 {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  cursor: default;
}

h2 {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  cursor: default;
}
