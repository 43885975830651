.clientesContainer {
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0;

  .list {
    height: calc(100vh - 20px);

    flex-grow: 1;

    display: flex;
    padding: 10px;
    padding-top: 0;
    z-index: 1;
  }

  .tarjeta {
    padding: 0.2rem 0;
    display: flex;
    justify-content: center;
  }
}

.noScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
