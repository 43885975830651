$large: 425px;
$tablet: 768px;
$laptop: 1024px;

.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;

  input {
    padding: 0.8rem;

    width: 100%;
    font-size: 1rem;

    border-radius: 8px;
  }

  @media screen and (min-width: $tablet) {
    width: 30%;
  }
}
